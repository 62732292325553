<template>
  <div style="height: 100%">
    <iframe
      id="popoutFrame"
      :src="iframeUrl"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>
<script>
import chartListener from "../mixins/chartListener";
export default {
  mixins: [chartListener],
  data() {
    return {
      iframeUrl: "",
    };
  },
  methods: {
    getIframe() {
      let checkStream = document.getElementById("popoutFrame");
      this.$store.commit("chartHandle/setPopOutRefresh", checkStream);
    },
  },
  destroyed() {
    this.$store.commit("chartHandle/setPopOutRefresh", null);
  },
  async mounted() {
    this.iframeUrl = JSON.parse(localStorage.getItem("iframeUrl"));
    await this.getIframe();




    // let checkStream = document.getElementById("popoutFrame");
    // let tickers = JSON.parse(window.localStorage.getItem("tickers"));
    // let message = {
    //   id: "stream",
    //   message: tickers,
    // };

    // setTimeout(() => {
    //   if (checkStream && checkStream.contentWindow) {
    //     checkStream.contentWindow.postMessage(message, "*");
    //   }
    // }, 500);

    // window.addEventListener("storage", () => {
    //   // When local storage changes, dump the list to
    //   // the console.
    //   tickers = JSON.parse(window.localStorage.getItem("tickers"));
    //   message = {
    //     id: "stream",
    //     message: tickers,
    //   };
    //   checkStream && checkStream.contentWindow
    //     ? checkStream.contentWindow?.postMessage(message, "*")
    //     : "";
    // });
  },
};
</script>
