import store from '@/store'
import error from '../store/Services/errorHandling'
import router from '../router'
var searchSym = null
export default {
    data(){
        return {

        }
    },
    methods:{
      chartListener(event){
        const msgData = event.data.data
       if(event.data.id === 'ChartPopout' && router.currentRoute.path == '/chart'){
        var data = store.state.tradeChartData;
        data.showPopout = false;
        data.isSymbolSearch = false;
        data.preloadProps = {
          interval: msgData.interval,
          indicators: msgData.indicators,
        };
          let url = window.location.protocol == 'https:' ? this.depolyUrl : this.appendUrl
          let iframeUrl = url + JSON.stringify(data)
          let newWindowURL
          if(window.location.hash.includes('#')){
            newWindowURL = `${window.location.protocol}//${window.location.host}/#/popoutChart`
          }else{
            newWindowURL = `${window.location.protocol}//${window.location.host}/popoutChart`
          }
          
          localStorage.setItem('iframeUrl' , JSON.stringify(iframeUrl))
          window.open(
            newWindowURL,
            "_blank"
          )
        } else if(event.data.id === 'startStream'){
          let streamSymbols = msgData.streamSyms??[]    // List of symbol array need to subscribe
          let streamSymbolDepth = msgData.streamSyms_L2??[]
          this.tokenList = msgData.tokenList
          store.commit("wsConnection/setChartSubscribe" , null)
          if(streamSymbols.length > 0){
            let symbol = ''
             streamSymbols.forEach(el =>{
              symbol +=el
             })
            var tempData = {
            data: [],
            subscribe: symbol,
            where: 'tradingChart'
          }
          store.commit("wsConnection/setChartSubscribe" , symbol)
          store.dispatch("wsConnection/websocketSubscription", tempData)
          }
        }else if(event.data.id === "currentSymbol" && router.currentRoute.path == '/chart'){
          let activeSymol = event.data.data.currentSymbol
       let lastData = store.state.tradeChartData
       lastData.symbol = activeSymol.symbol
       lastData.token = activeSymol.token
       lastData.exchange = activeSymol.exchange
       store.commit('setTardeData', lastData)
      }else if(event.data.id === "apiError"){
          let msg = {
            response :{
              status : event.data.data.error
            }
          }
          error.errLog(msg)
      }else if(event.data.id === "orderWindow"){
          let data = event.data.data
         if(data.orderType == "NEW ORDER" || data.orderType == "MODIFY ORDER" || data.orderType == "CLOSE POSITION"){
          data.ex = data.exc
          data.price = !data.price ? '' :  data.ex == 'CDS' || data.ex == 'BCD' ? parseFloat(data.price).toFixed(4) : parseFloat(data.price).toFixed(2)
          let isModify = false
          let isBasket = false
          let isBasketModify = false
          let isRepeat = false
          let page = 'chart'
          var orderType = data.tradeType ? data.tradeType.toString().toLowerCase() : ''
          let isInstantOrder = false
          // data.isInstantOrder ? isInstantOrder = data.isInstantOrder : '' 
          if((data.orderType == "MODIFY ORDER" && data.orderParams) || (data.positionParams && data.orderType == "CLOSE POSITION")){
            let whichOrderType = data.orderType
            isModify = whichOrderType == "MODIFY ORDER" ? true : false
            let tempPrice = data.price
            data = whichOrderType == "MODIFY ORDER" ?  data.orderParams : data.positionParams
            if(whichOrderType == "MODIFY ORDER"){
              if(tempPrice){
                data.Prctype == "L" ? data.Prc = tempPrice : data.Trgprc = tempPrice 
              }
            }
            data.ex = data.Exchange
            page = whichOrderType == "MODIFY ORDER" ? 'orders' : 'positions'

            if(whichOrderType == "CLOSE POSITION"){
              data.token = data.Token
              orderType = Number(data.Netqty) > 0 ? 'sell' : 'buy'
            }else{
              orderType = data.Trantype == 'B' ? 'buy' : 'sell'
            }
          }
          isInstantOrder ? this.$store.dispatch("orderWindow/getContract", {data, orderType, isModify , page , isBasket , isBasketModify, isRepeat, isInstantOrder})
          : this.$store.dispatch("orderWindow/getContract", {data, orderType, isModify , page , isBasket , isBasketModify, isRepeat})
         }
         if(data.orderType == "CANCEL ORDER"){
          store.commit("chartHandle/setChartCancel", true)
          store.commit('chartHandle/setCancelArray', data.orderParams)
         }
      }else if (event.data.id === "indicatorsStoring" && (router.currentRoute.path == '/chart' || router.currentRoute.path == '/popoutChart')) {
          const msgData = event.data.data
          store.commit("setChartIndicators", msgData);
        }
      }
    },
    mounted(){
        window.addEventListener('message', this.chartListener)
    },
    destroyed(){
      window.removeEventListener('message' , this.chartListener)
      store.commit("chartHandle/setRefreshArray", '')
    }
}